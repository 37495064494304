export const custom_main_menu_event_name = 'MAIN_MENU_TOGGLE'

export const menu_opening_event = new CustomEvent(custom_main_menu_event_name, {
  detail: 'opening',
  bubbles: false
})
export const menu_opened_event = new CustomEvent(custom_main_menu_event_name, {
  detail: 'open',
  bubbles: false
})
export const menu_closed_event = new CustomEvent(custom_main_menu_event_name, {
  detail: 'closed',
  bubbles: false
})
export const menu_closing_event = new CustomEvent(custom_main_menu_event_name, {
  detail: 'closing',
  bubbles: false
})
export const external_close_menu = new CustomEvent(custom_main_menu_event_name, {
  detail: 'close_from_external_src',
  bubbles: false
})

export type menu_opening_event = CustomEvent
