export const debounce = (mainFunction: (...args: unknown[]) => void, delay: number) => {
  // Declare a variable called 'timer' to store the timer ID
  let timer: number | undefined

  // Return an anonymous function that takes in any number of arguments
  return function (...args: unknown[]) {
    // Clear the previous timer to prevent the execution of 'mainFunction'
    clearTimeout(timer)

    // Set a new timer that will execute 'mainFunction' after the specified delay
    timer = setTimeout(() => {
      mainFunction(...args)
    }, delay)
  }
}

export const throttle = (func: (...args: unknown[]) => void, limit: number) => {
  let inThrottle: boolean
  return function (this: unknown, ...args: unknown[]) {
    if (!inThrottle) {
      func.apply(this, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  }
}
