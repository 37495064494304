// Our main CSS
import '../css/app.css'

import MainNavigation from './modules/site-navigation'
;(async () => {
  await MainNavigation()

  const copy_to_clipboard = document.querySelectorAll(
    '[data-to-clipboard]'
  ) as NodeListOf<HTMLElement>
  if (copy_to_clipboard && copy_to_clipboard.length) {
    copy_to_clipboard.forEach(async (item) => {
      await import('./modules/copy-to-clipboard').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }

  const program_filter = document.querySelector('[data-program-filter-app]')
  if (program_filter) {
    await import('./components/program-filter').then((module) =>
      module.default(program_filter as HTMLElement)
    )
  }
  const trainee_program_sidebar = document.querySelector(
    '.TraineeProgramPage .PageAsideCard'
  ) as HTMLElement
  if (trainee_program_sidebar) {
    await import('./modules/traineeprogram-sidebar').then((module) => {
      module.default(trainee_program_sidebar as HTMLElement)
    })
  }
  const autoplaying_videos = document.querySelectorAll(
    'video[playsinline]'
  ) as NodeListOf<HTMLVideoElement>
  if (autoplaying_videos && autoplaying_videos.length) {
    autoplaying_videos.forEach(async (video) => {
      await import('./modules/autoplaying-videos').then((module) => {
        module.default(video as HTMLMediaElement)
      })
    })
  }

  const swiper_image_slideshow = document.querySelectorAll(
    '.swiper-image-slideshow'
  ) as NodeListOf<HTMLElement>
  if (swiper_image_slideshow && swiper_image_slideshow.length) {
    swiper_image_slideshow.forEach(async (item) => {
      await import('./modules/swiper-image-slideshow').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }
  const swiper_company_slider = document.querySelectorAll(
    '.swiper-company-slider'
  ) as NodeListOf<HTMLElement>
  if (swiper_company_slider && swiper_company_slider.length) {
    swiper_company_slider.forEach(async (item) => {
      await import('./modules/swiper-company-slider').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }

  const swiper_small_cards_slider = document.querySelectorAll(
    '.swiper-small-card-slider'
  ) as NodeListOf<HTMLElement>
  if (swiper_small_cards_slider && swiper_small_cards_slider.length) {
    swiper_small_cards_slider.forEach(async (item) => {
      await import('./modules/swiper-small-card-slider').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }

  const accordion_blocks = document.querySelectorAll('.AccordionBlock') as NodeListOf<HTMLElement>
  if (accordion_blocks && accordion_blocks.length) {
    accordion_blocks.forEach(async (item) => {
      await import('./modules/accordion-block').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }
  const swiper_latest_articles_slider = document.querySelectorAll(
    '.swiper-latest-articles-slider'
  ) as NodeListOf<HTMLElement>
  if (swiper_latest_articles_slider && swiper_latest_articles_slider.length) {
    swiper_latest_articles_slider.forEach(async (item) => {
      await import('./modules/swiper-latest-articles-slider').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }

  const swiper_premium_programs_slider = document.querySelectorAll(
    '.swiper-premium-programs-slider'
  ) as NodeListOf<HTMLElement>
  if (swiper_premium_programs_slider && swiper_premium_programs_slider.length) {
    swiper_premium_programs_slider.forEach(async (item) => {
      await import('./modules/swiper-premium-programs-slider').then((module) => {
        module.default(item as HTMLElement)
      })
    })
  }

  const premium_trainee_program_video = document.querySelector(
    '.TraineeProgramPage.PremiumLayout figure[data-hero-media] video'
  ) as HTMLElement
  if (premium_trainee_program_video) {
    await import('./modules/traineeprogram-premium-video').then((module) => {
      module.default(premium_trainee_program_video as HTMLVideoElement)
    })
  }
})()
