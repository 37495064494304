import {
  custom_main_menu_event_name,
  menu_closing_event,
  menu_opening_event,
  menu_opened_event,
  menu_closed_event
} from '../custom-events'
import { throttle } from '../utils'

type UI_STATE = {
  open: boolean
  transitioning: boolean
  type: string
  current_target: HTMLElement | null
  elements: Array<HTMLElement | null>
}
const ui_state: UI_STATE = {
  open: false,
  transitioning: false,
  type: '',
  current_target: null,
  elements: []
}

let open_button: HTMLElement | null
let menu_element: HTMLElement | null
let nav_menu: HTMLElement | null
let menu_button: HTMLElement | null

// Create a background element to close the drawer

// handler for when the drawer is transitioning
function drawerTransitionStartedEventHandler(e: TransitionEvent) {
  if (e.target !== ui_state.current_target && e.propertyName !== 'clip-path') {
    return false
  }
  // Set the transitioning state to true and add the attribute to the drawer
  ui_state.transitioning = true
  nav_menu?.setAttribute('data-is-transitioning', ui_state.transitioning.toString())
  // Disable the menu button while transitioning
  menu_button?.setAttribute('disabled', '')
}

// Hndler for when the drawer is done transitioning
function drawerTransitionEndedEventHandler(e: TransitionEvent) {
  if (e.target !== ui_state.current_target && e.propertyName !== 'clip-path') {
    return false
  }
  // Set the transitioning state to false and add the attribute to the drawer
  ui_state.transitioning = false
  nav_menu?.setAttribute('data-is-transitioning', ui_state.transitioning.toString())
  // Enable the menu button again
  menu_button?.removeAttribute('disabled')
  // set the aria-hidden attribute for the current target
  nav_menu?.setAttribute('aria-hidden', (!ui_state.open).toString())

  // add atribute to body
  document.body.setAttribute('data-menu-visible', ui_state.open.toString())

  if (!ui_state.open) {
    window.dispatchEvent(menu_closed_event)
  } else {
    // Dispatch custom event
    window.dispatchEvent(menu_opened_event)
  }
}
// register the event handlers for the drawer
function registerTransitionEventHandlers() {
  // Eventlisterns for the transsitioning
  ui_state.current_target?.addEventListener('transitionstart', drawerTransitionStartedEventHandler)
  ui_state.current_target?.addEventListener('transitionend', drawerTransitionEndedEventHandler)
}

function updateMenuButtonLabel() {
  if (!menu_button) {
    return false
  }

  // Update the text in the menu_button
  if (menu_button) {
    const btn_label_text = ui_state.open
      ? menu_button.getAttribute('data-btn-label-open')
      : menu_button.getAttribute('data-btn-label-closed')
    const aria_label_text = ui_state.open
      ? menu_button.getAttribute('data-label-closed')
      : menu_button.getAttribute('data-label-open')

    // Set aria-attributes
    menu_button.setAttribute('aria-expanded', ui_state.open.toString())
    menu_button.setAttribute('aria-pressed', ui_state.open.toString())
    menu_button.classList.toggle('ui-state-active', ui_state.open)
    menu_button.setAttribute('aria-label', aria_label_text ? aria_label_text : '')
    if (ui_state.open) {
      menu_button.setAttribute('tabindex', '0')
    }
    menu_button?.setAttribute('aria-controls', ui_state.current_target?.id ?? '')

    // menu_button?.setAttribute('data-drawer-type', ui_state.type ?? '')

    const btn_label: HTMLElement | null = menu_button.querySelector('.MenuButtonLabel') ?? null
    if (btn_label) {
      btn_label.innerText = btn_label_text ? btn_label_text : ''
    }
  }
}

function closeMenuOnResize(e: Event) {
  const target = e.target as Window
  if (ui_state.open && target?.innerWidth > 767) {
    closeMenu()
  }
}

function openMenu() {
  ui_state.open = true

  // Dispatch custom event
  window.dispatchEvent(menu_opening_event)

  ui_state.current_target?.setAttribute('aria-hidden', 'false')
  ui_state.current_target?.setAttribute('data-open', 'true')
  document.body.setAttribute('data-nav-open', 'true')

  window.addEventListener('resize', (e) => {
    return throttle(closeMenuOnResize(e), 400)
  })
  updateMenuButtonLabel()

  // Allow the elements display:none to be removed before transitioning
  return setTimeout(function () {
    menu_element?.setAttribute('data-ui-visible', ui_state.open.toString())
  }, 100)
}

function closeMenu() {
  ui_state.open = false
  // Dispatch custom event
  window.dispatchEvent(menu_closing_event)
  ui_state.type = ''
  // the default value for the aria-controls attribute
  const default_val = menu_button?.getAttribute('data-control-for') ?? ''
  menu_button?.setAttribute('aria-controls', default_val)
  // Set the aria-hidden attribute to true to hide the drawer
  menu_element?.setAttribute('data-ui-visible', ui_state.open.toString())
  ui_state.current_target?.setAttribute('aria-hidden', 'true')
  ui_state.current_target?.setAttribute('data-open', 'false')
  document.body.setAttribute('data-nav-open', 'false')

  window.removeEventListener('resize', (e) => closeMenuOnResize)
  updateMenuButtonLabel()

  return
}

// main fn to toggle main nav or search
function toggleMenu() {
  // if its open, close it, otherwise open it
  if (ui_state.open) {
    closeMenu()
  } else {
    openMenu()
  }

  registerTransitionEventHandlers()
}

function MainNavigation() {
  // the drawer holder
  menu_element = document.querySelector('#MainNavigation') // Bail if we dont have the drawer holder elem
  if (!menu_element) {
    return
  }
  nav_menu = menu_element.querySelector('#SiteNavigation')
  ui_state.current_target = nav_menu
  const mobile_menu_top_offset_value = menu_element?.getBoundingClientRect().bottom + 16
  menu_element.style.setProperty('--mobile-nav-top-padding', `${mobile_menu_top_offset_value}px`)

  if (!nav_menu) {
    return
  }

  // the menu button in the header. acts as close btn for the search as well
  menu_button = menu_element.querySelector('#MainMenuToggleButton')

  if (!menu_button) {
    return
  }

  // Add event listeners to the buttons to open/close the drawer
  const triggers = [menu_button]
  triggers.forEach((button) => {
    button?.addEventListener('click', (e) => {
      open_button = e.currentTarget as HTMLElement
      toggleMenu()
    })
  })

  // Custom event listeners for the menu
  window.addEventListener(custom_main_menu_event_name, (e) => {
    const event = e as CustomEvent
    if (!menu_element) {
      return
    }
    switch (event.detail) {
      case menu_closed_event.detail:
        menu_element.classList.remove('menu-is-closing')
        menu_element.classList.add('menu-is-closed')
        break
      case menu_opening_event.detail:
        menu_element.classList.add('menu-is-opening')
        menu_element.classList.remove('menu-is-closed')
        break
      case menu_opened_event.detail:
        menu_element.classList.remove('menu-is-opening')
        menu_element.classList.add('menu-is-open')
        break
      case menu_closing_event.detail:
        menu_element.classList.add('menu-is-closing')
        menu_element.classList.remove('menu-is-open')
        break
    }
  })
}

export default MainNavigation
